<script setup lang="ts">
    interface AccountSectionProps {
        title: string;
        sectionTitle?: string;
        sectionImage: string;
        description: string;
        showEdit?: boolean;
        disableButton?: boolean;
        noContentMessage?: string;
        noContentMessageShow?: boolean;
        isProfile?: boolean;
    }

    withDefaults(defineProps<AccountSectionProps>(), { isProfile: false });

    const emit = defineEmits(['open:dialog']);

    const handleClick = () => {
        emit('open:dialog');
    };
</script>
<template>
    <section class="mol-account-section flex flex-col gap-4">
        <div class="flex items-center justify-start gap-4">
            <div class="h-20 w-16 shrink-0 self-start">
                <atm-image
                    :src="sectionImage"
                    class="[&_img]:max-h-20"
                    sizes="64px"
                    role="presentation" />
            </div>
            <div class="flex flex-col gap-1">
                <atm-heading size="md">{{ title }}</atm-heading>
                <p class="text-sm">{{ description }}</p>
            </div>
        </div>
        <div class="flex flex-col gap-4 rounded-lg bg-woom-white p-4 md:p-5">
            <slot name="actions">
                <button
                    class="edit-button flex items-center gap-4 py-1"
                    @click="handleClick"
                    :disabled="disableButton">
                    <atm-heading
                        v-if="sectionTitle"
                        size="sm"
                        level="span"
                        class="edit-button-hover:text-woom-red transition-colors">
                        {{ sectionTitle }}
                    </atm-heading>
                    <span :class="['flex gap-3', disableButton ? 'text-woom-grey-moon' : 'text-woom-red']">
                        <template v-if="!showEdit">
                            <span>{{ $t('account.add') }}</span>
                            <woom-icon-plus class="h-5 w-5 fill-current" />
                        </template>
                        <woom-icon-edit
                            v-else
                            class="h-5 w-5 fill-current" />
                    </span>
                </button>
                <atm-form-error
                    v-if="disableButton"
                    no-indent
                    :error="$t('account.maxLimitReached', { sectionTitle })"
                    class="text-woom-grey-moon" />
            </slot>

            <slot> </slot>
            <lazy-atm-alert
                v-if="noContentMessageShow"
                :message="noContentMessage" />
        </div>
    </section>
</template>
